<template>
  <li class="chapter">
    <div class="chapter-item" :style="chapterStyle">
      <div
        class="enlarge"
        v-if="chapterInfo.children.length !== 0"
        @click="isFold"
      >
        <img src="../assets/add.png" alt="" v-if="!folder" />
        <img src="../assets/delete.png" alt="" v-else />
      </div>
      <div
        class="name"
        @click="goCourseContent(chapterInfo.id)"
        :style="{ color: color }"
      >
        {{ chapterInfo.name }}
      </div>
    </div>

    <div v-if="folder && chapterInfo.children">
      <li v-for="(item, index) in chapterInfo.children" :key="index">
        <chapter
          :chapterInfo="item"
          :marginPx="marginPx + 20"
          :classId="classId"
          :courseId="courseId"
        ></chapter>
      </li>
    </div>
  </li>
</template>

<script>
export default {
  name: "chapter",
  props: {
    chapterInfo: Object,
    marginPx: Number,
    classId: Number,
    courseId: Number,
  },
  data() {
    return {
      chapterStyle: `margin-left:${this.marginPx}px;`,
      folder: false,
      color: "",
    };
  },
  mounted() {
    // 展开选择的选项
    this.chapterInfo.children.forEach((item, index) => {
      if (item.children.length > 0) {
        item.children.forEach((item1, index1) => {
          this.isSelect(item1.id);
        });
      } else {
        this.isSelect(item.id);
      }
    });
    // 修改字体颜色
    if (this.chapterInfo.children.length == 0 && this.chapterInfo.id == this.courseId) {
      this.color = "#4394ff";
    }
  },
  methods: {
    isSelect(id) {
      if (id == this.courseId) {
        this.folder = true;
      }
    },
    isFold() {
      this.folder = !this.folder;
    },
    goCourseContent(id) {
      if (this.chapterInfo.children.length > 0) {
        if (this.folder) {
          this.folder = false;
        } else {
          this.folder = true;
        }
      } else {
        this.open(id);
      }
    },
    async open(id) {
      await this.$router.push({
        path: "/courseContent",
        query: { id: id, classId: this.classId },
      });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.chapter-item {
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  padding-bottom: 20px;
  .enlarge {
    width: 22px;
    height: 22px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>